@import url("https://fonts.cdnfonts.com/css/poppins");
$primary-color: #1a9c5b;
$primart-text: white;

body {
  font-size: 14px;
  // font-family: Poppins !important;
}

body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: black; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: $primary-color; /* color of the scroll thumb */
  border: 1px solid black; /* creates padding around scroll thumb */
}

.margin-app {
  min-height: 500px;
}

.signUpBtn,
.get-started-btn {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  font-weight: 500 !important;
  color: $primart-text !important;
  span {
    font-weight: 500 !important;
    color: $primart-text !important;
  }
}

.signUpBtn:disabled {
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  color: $primart-text !important;
  span {
    font-weight: 500 !important;
    color: $primart-text !important;
  }
}

.signUpBtn:hover,
.signUpBtn:focus,
.get-started-btn:hover,
.get-started-btn:focus {
  color: $primart-text !important;
  border-color: $primary-color !important;
}

.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: white !important;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  touch-action: manipulation;
  height: 60px;
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  z-index: 1;
  background: linear-gradient(
    60deg,
    #ff7f50,
    #8b0000,
    #229ed9,
    #25d366,
    #ff7f50,
    #8b0000
  );
  animation: animatedgradient 3s ease infinite alternate;
  background-size: 300% 300%;
}

.button-85:hover {
  transform: scale(0.94);
}

.button-85:before {
  content: "";
  background: linear-gradient(45deg, #229ed9, #25d366, #ff7f50, #8b0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    60deg,
    #ff7f50,
    #8b0000,
    #229ed9,
    #25d366,
    #ff7f50,
    #8b0000
  );
  animation: glowing-button-85 20s linear infinite;
  background-size: 300% 300%;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.gradient-btn {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  background: linear-gradient(45deg, #ff6f00, #ff3d00);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gradient-btn:hover {
  background: linear-gradient(45deg, #ff3d00, #ff6f00);
}

.react-video-player-custom {
  iframe {
    border-radius: 20px;
  }
}
